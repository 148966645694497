<template>
  <div v-if="content" class="container">
    <div class="row row-cols-2">
      <div class="col logo">
        <img
          src="/images/logo.jpeg"
          alt="Ifero 2.0"
          class="d-inline-block align-text-top"
        />
      </div>
      <Header :content="content" :type="$route.params.type" />
      <div class="col-7 small">
        Prodavac:<br />
        <div class="border-top border-bottom small">
          {{ seller.name }}<br />
          {{ seller.addr }}<br />
          PIB: {{ seller.pib }}<br />
          Matični broj: {{ seller.mb }}<br />
          Tekući račun: {{ seller.tr2 }}<br />
          Tel: {{ seller.tel }}
        </div>
      </div>
      <div class="col-5 small">
        Kupac:
        <div class="border-top border-bottom small">
          {{ content.Buyer ? content.Buyer.name : "" }}<br />
          {{ content.Buyer ? content.Buyer.address : "" }}<br />
          {{ content.Buyer ? content.Buyer.zip : "" }}
          {{ content.Buyer ? content.Buyer.city : "" }},
          {{ content.Buyer ? content.Buyer.country : "" }}<br />
          PIB: {{ content.Buyer ? content.Buyer.pib : "" }}<br />
          Matični broj: {{ content.Buyer ? content.Buyer.mb : "" }} <br />
          Tekući račun: {{ content.Buyer ? content.Buyer.bank_account : "" }}<br />
        </div>
      </div>
    </div>
    <div class="row md-3">
      <div class="col">
        <table class="table small table-sm">
          <thead>
            <tr class="table-secondary small">
              <th scope="col">R.B.</th>
              <th scope="col">PREDMET PRODAJE</th>
              <th scope="col">J.M.</th>
              <th scope="col">KOL.</th>
              <th scope="col" class="text-end">JEDINIČNA CENA</th>
              <th scope="col" class="text-end">VREDNOST BEZ PDV-a</th>
              <th scope="col" class="text-end">PDV</th>
              <th scope="col" class="text-end">PDV IZNOS</th>
              <th scope="col" class="text-end">VREDNOST SA PDV-om</th>
            </tr>
          </thead>
          <tbody>
            <tr class="small">
              <th scope="row">1</th>
              <td>{{ content.article ? content.article.name : "" }}</td>
              <td>kom</td>
              <td>1</td>
              <td class="text-end">
                {{ prices ? numFormat(prices.vat_base) : "" }}
              </td>
              <td class="text-end">
                {{ prices ? numFormat(prices.vat_base) : "" }}
              </td>
              <td class="text-end">{{ prices ? prices.rate : "" }}</td>
              <td class="text-end">
                {{ prices ? numFormat(prices.vat) : "" }}
              </td>
              <td class="text-end">
                {{ prices ? numFormat(prices.amount) : "" }}
              </td>
            </tr>
            <tr class="small">
              <td></td>
              <td colspan="2">
                <div class="">
                  Broj ugovora:<br />

                  Marka vozila:<br />
                  Model vozila:<br />
                  Broj registracije:<br />
                  Broj šasije vozila:<br />
                  Broj motora vozila:<br />
                  Godina proizvodnje:<br />
                  Snaga motora (KW):<br />
                  Radna zapremina (ccm):<br />
                  Broj sedišta:<br />
                  Boja karoserije:<br />

                  Pređena kilometraža (KM):<br />
                  Prva registracija: <br />
                  Masa (KG):
                </div>
              </td>
              <th colspan="6">
                <div class="">
                  {{ content.contract_nr }}<br />

                  {{ content.brand }}<br />
                  {{ content.model }}<br />

                  {{ content.registration_nr }}<br />
                  {{ content.chassis_nr }}<br />
                  {{ content.engine_nr }}<br />
                  {{ content.year }}<br />
                  {{ content.engine_power }}<br />
                  {{ content.engine_volume }}<br />
                  {{ content.seats }}<br />
                  {{ content.color }}<br />

                  {{ content.km }}<br />
                  {{ dateFormat(content.first_registration) }}<br />
                  {{ content.mass }}
                </div>
              </th>
            </tr>
            <tr class="table-secondary small text-end">
              <th colspan="3" class="text-start">UKUPNO</th>
              <td class="text-start">1</td>
              <td></td>
              <td>{{ prices ? numFormat(prices.vat_base) : "" }}</td>
              <td></td>
              <td>{{ prices ? numFormat(prices.vat) : "" }}</td>
              <td>{{ prices ? numFormat(prices.amount) : "" }}</td>
            </tr>
            <tr class="small text-end">
              <td :colspan="$route.params.type == 'ir' ? 7 : 8"></td>
              <th class="table-secondary">
                {{ $route.params.type == "pr" ? "EUR" : "RSD" }}
              </th>
              <th v-if="$route.params.type == 'ir'" class="table-secondary">
                EUR
              </th>
            </tr>
            <tr class="small text-end">
              <td
                :colspan="$route.params.type == 'ir' ? 4 : 5"
                style="border: 0"
              ></td>
              <th colspan="3" class="table-secondary text-end">
                PORESKA OSNOVICA:
              </th>
              <td v-if="$route.params.type == 'ir'">
                {{
                  prices && invoice
                    ? numFormat(prices.vat_base * invoice.exchange ?? 1)
                    : ""
                }}
              </td>
              <td>{{ prices ? numFormat(prices.vat_base) : "" }}</td>
            </tr>
            <tr class="small text-end">
              <td
                :colspan="$route.params.type == 'ir' ? 4 : 5"
                style="border: 0"
              ></td>
              <th colspan="3" class="table-secondary text-end">PDV:</th>
              <td v-if="$route.params.type == 'ir'">
                {{
                  prices && invoice
                    ? numFormat(prices.vat * invoice.exchange ?? 1)
                    : ""
                }}
              </td>
              <td>{{ prices ? numFormat(prices.vat) : "" }}</td>
            </tr>
            <tr class="small text-end">
              <td
                :colspan="$route.params.type == 'ir' ? 4 : 5"
                style="border: 0"
              ></td>
              <th colspan="3" class="table-secondary text-end">
                UKUPNO ZA PLAĆANE:
              </th>
              <td v-if="$route.params.type == 'ir'">
                {{
                  prices && invoice
                    ? numFormat(prices.amount * invoice.exchange ?? 1)
                    : ""
                }}
              </td>
              <td>{{ prices ? numFormat(prices.amount) : "" }}</td>
            </tr>
            <tr
              v-if="$route.params.type != 'pr'"
              class="table-secondary small text-end"
            >
              <th colspan="5" class="text-start">PORESKE STOPE</th>
              <th>OSNOVA</th>
              <th colspan="2">PDV</th>
              <th>VREDNOST</th>
            </tr>
            <tr
              v-if="$route.params.type != 'pr' && $route.params.type != 'ir'"
              class="small text-end"
            >
              <td colspan="5" class="text-start">
                Promet usluga po opštoj stop 20%
              </td>
              <td>{{ prices ? numFormat(prices.vat_base) : "" }}</td>
              <td colspan="2">{{ prices ? numFormat(prices.vat) : "" }}</td>
              <td>{{ prices ? numFormat(prices.amount) : "" }}</td>
            </tr>
            <tr
              v-if="$route.params.type != 'pr' && $route.params.type == 'ir'"
              class="small text-end"
            >
              <td colspan="5" class="text-start">
                Promet usluga po opštoj stop
                {{
                  content && content["tax-rate"]
                    ? content["tax-rate"]["rate"] ?? 0.0
                    : 0.0
                }}%
              </td>
              <td>
                {{
                  prices && invoice
                    ? numFormat(prices.vat_base * invoice.exchange ?? 1)
                    : ""
                }}
              </td>
              <td colspan="2">
                {{
                  prices && invoice
                    ? numFormat(prices.vat * invoice.exchange ?? 1)
                    : ""
                }}
              </td>
              <td>
                {{
                  prices && invoice
                    ? numFormat(prices.amount * invoice.exchange ?? 1)
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row md-3">
      <div class="col small">
        <pre>{{
          invoice
            ? invoice.comment
                .replaceAll("$datum_kursa", dateFormat(invoice.exchange_date))
                .replaceAll("$kurs_na_datum", invoice.exchange)
            : ""
        }}</pre>
      </div>
    </div>
    <div class="md-2">
      <div class="col small">
        U iznos fakture uključeni su svi troškovi / All costs are included in the invoice amount.
      </div>
      <div class="col small">
        Ovaj dokument je izrađen automatskom obradom podataka i važeći je bez
        pečata i potpisa.
      </div>
    </div>
    <Foother :type="$route.params.type" />
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
import utils from "../../utils/common";
import Header from "./header.vue";
import Foother from "./foother.vue";
export default {
  name: "invoice-prod",
  components: {
    Header,
    Foother,
  },
  props: ["content"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    numFormat(num) {
      return utils.toNumberFormat(num);
    },
  },
  data() {
    // return {
    //   content: {},
    // };
  },
  computed: {
    taxRates() {
      return this.$store.state.app.taxRates;
    },
    getTax() {
      const tax = this.content.article ? this.taxRates.find((t) => t.id == this.content.article.tax) : {rate: 20};
      return tax.rate;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    seller() {
      return this.$store.state.app.seller;
    },
    invoice() {
      if (this.$route.params.type == "pr") {
        console.log(this.$route.params.docid);
        return this.content["sell_proforms"]
          ? this.content["sell_proforms"].find(
              (pf) => pf.id == this.$route.params.docid
            )
          : null;
      } else {
        return this.content["sell_invoice"];
      }
    },
    prices() {
      const vatRate = this.getTax;
      let base = this.content["bb_neto_eur"];
      if (this.$route.params.type == "r" || this.$route.params.type == "sr") {
        base =
          this.invoice && this.invoice["exchange"]
            ? (this.content["bb_neto_eur"] * this.invoice["exchange"]).toFixed(
                2
              )
            : this.content["bb_neto_rsd"];
      }
      const vats = utils.vatCalculate(base, vatRate);
      if (this.$route.params.type == "sr") {
        return {
          vat_base: Number(base) * -1,
          vat: Number(vats.vat) * -1,
          amount: Number(vats.amount) * -1,
          rate: Number(vatRate),
        };
      } else {
        return {
          vat_base: Number(base),
          vat: Number(vats.vat),
          amount: Number(vats.amount),
          rate: Number(vatRate),
        };
      }
    },
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid gray; */
  width: 800px;
  height: 900px;
}
.container .row > .col,
.container .row > [class^="col-"] {
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
  /* background-color: rgba(39, 41, 43, 0.03); */
  /* border: 1px solid rgba(39, 41, 43, 0.1); */
}

.logo {
  padding: 0 !important;
}

img {
  height: 120px;
}

.sig {
  float: right;
}
tr {
  vertical-align: middle;
}
pre {
  white-space: pre-wrap;
}
</style>