<template>
  <div v-if="content" class="container">
    <div class="row">
      <div class="col-4 logo">
        <img
          src="/images/logo.jpeg"
          alt="Ifero 2.0"
          class="d-inline-block align-text-top"
        />
      </div>
      <div class="col-4 small">
        <div class="small">
          {{ seller.name }}<br />
          {{ seller.addr }}<br />
          Tel: {{ seller.tel }}
        </div>
      </div>
      <div class="col-4 small">
        <div class="small">
          PIB: {{ seller.pib }}<br />
          Matični broj: {{ seller.mb }}<br />
          Tekući račun: {{ seller.tr2 }}<br />
        </div>
      </div>
    </div>
    <div class="row p-5">
      <div class="col small text-center">
        <h1>ODLUKA</h1>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        {{ seller.name }} {{ seller.addr }} Matični broj: {{ seller.mb }} PIB:
        {{ seller.pib }}, donosi odluku da zbog isteka ugovora o lizingu i zbog
        smanjenja potreba za izdavanjem prodaje vozilo:
      </div>
    </div>
    <div class="row border-top border-bottom">
      <div class="col small">
        Marka vozila:<br />
        Model vozila:<br />
        Broj šasije:<br />
        Broj motora:<br />
        Broj registracije:<br />
      </div>
      <div class="col-10 small fw-bold">
        {{ content.brand }}<br />
        {{ content.model }}<br />
        {{ content.chassis_nr }}<br />
        {{ content.engine_nr }}<br />
        {{ content.registration_nr }}<br />
      </div>
    </div>
    <Foother :type="$route.params.type" :date="content.invoices
            ? getInvoiceDate
            : dateFormat(content.cession_date ?? content.createdAt)" />
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
import utils from "../../utils/common";
import Foother from "./foother.vue";
export default {
  name: "decision",
  components: {
    Foother,
  },
  props: ["content"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    numFormat(num) {
      return utils.toNumberFormat(num);
    },
  },
  data() {
    // return {
    //   content: {},
    // };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    seller() {
      return this.$store.state.app.seller;
    },
    getInvoiceDate() {
      /// return date from first invoice with type Normal and status Done
      if (!this.content.invoices) return "";
      // console.log(this.content.invoices)
      const normalDoneInvoice = this.content.invoices.find(
        (i) => i.invoice_type == "Normal" && i.invoice_status == "Done"
      );
      /// if getInvoiceDate is null try to find New invoice
      if (!normalDoneInvoice) {
        const normalNewInvoice = this.content.invoices.find(
          (i) => i.invoice_type == "Normal" && i.invoice_status == "New"
        );
        return normalNewInvoice ? dateUtils.formatDate(normalNewInvoice.doc_date) : "__.__.____";
      }
      return normalDoneInvoice ? dateUtils.formatDate(normalDoneInvoice.doc_date) : "__.__.____";
    },
    prices() {
      const vatRate =
        this.content["cession_invoice"] &&
        this.content["cession_invoice"]["tax-rate"]
          ? Number(this.content["cession_invoice"]["tax-rate"]["rate"])
          : 0.0;
      const base =
        this.content["cession_invoice"] &&
        this.content["cession_invoice"]["exchange"]
          ? (
              this.content["bb_neto_eur"] *
              this.content["cession_invoice"]["exchange"]
            ).toFixed(2)
          : this.content["bb_neto_rsd"];
      const vats = utils.vatCalculate(base, vatRate);
      return {
        vat_base: Number(base),
        vat: Number(vats.vat),
        amount: Number(vats.amount),
        rate: Number(vatRate),
      };
    },
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid gray; */
  width: 800px;
  height: 900px;
}
.container .row > .col,
.container .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* background-color: rgba(39, 41, 43, 0.03); */
  /* border: 1px solid rgba(39, 41, 43, 0.1); */
}

.logo {
  padding: 0 !important;
}

img {
  height: 120px;
}

.sig {
  float: right;
}
tr {
  vertical-align: middle;
}
</style>