<template>
  <div v-if="content" class="container">
    <div class="row row-cols-2">
      <div class="col logo">
        <img
          src="/images/logo.jpeg"
          alt="Ifero 2.0"
          class="d-inline-block align-text-top"
        />
      </div>
      <div class="col small">
        datum:
        {{ content.invoices
            ? getInvoiceDate
            : content.sell_form ? dateFormat(content.sell_form.createdAt) : "" }}
      </div>
    </div>
    <div class="row p-2">
      <div class="col small text-center">
        <h3>
          Zapisnik o primopredaji i stanju polovnog motornog vozila po ugovoru
          br.
          {{ content.doc_nr ? content.doc_nr.replace("PO-", "U-") : "" }}
        </h3>
      </div>
    </div>
    <div v-for="(row, index) in formFields.rows" :key="index">
      <div class="col" v-for="(val, index) in row" :key="index">
        <div class="" v-for="(value, propertyName) in val" :key="propertyName">
          <div class="row" v-if="val && val[propertyName].type == 'h5'">
            <div class="col small">
              <div class="small fw-bold fs-5">
                {{ val[propertyName].label }}
              </div>
            </div>
          </div>
          <div class="row" v-else-if="val && val[propertyName].type == 'text'">
            <div class="col small py-0">{{ val[propertyName].label }}</div>
            <div class="col small py-0">
              {{
                content.sell_form && content.sell_form[propertyName]
                  ? content.damages && !val[propertyName].noDamageLabel ? 'Totalna šteta' : content.sell_form[propertyName]
                  : content.damages && !val[propertyName].noDamageLabel ? 'Totalna šteta' : val[propertyName].default ?? "-"
              }}
            </div>
          </div>
          <div class="row" v-else>
            <div class="col small py-0">{{ val[propertyName].label }}</div>
            <div class="col small py-0">
              {{
                content.sell_form && content.sell_form[propertyName]
                  ? content.damages && !val[propertyName].noDamageLabel ? 'Totalna šteta' : val[propertyName].ifTrue ?? "ispravno i funkcionalno"
                  : content.damages && !val[propertyName].noDamageLabel ? 'Totalna šteta' : val[propertyName].default ?? "-"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-5">
      <div class="col text-center small">
        PRODAVAC<br /><br />
        _______________________<br />
        {{ seller.name }}
      </div>
      <div class="col text-center small"></div>
      <div class="col text-center small">
        KUPAC<br /><br />
        _______________________<br />
        {{ content.Buyer ? content.Buyer.name : "" }}
      </div>
    </div>
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
import utils from "../../utils/common";
// import Foother from "./foother.vue";
export default {
  name: "minutes",
  components: {
    // Foother,
  },
  props: ["content"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    numFormat(num) {
      return utils.toNumberFormat(num);
    },
  },
  data() {
    // return {
    //   content: {},
    // };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    seller() {
      return this.$store.state.app.seller;
    },
    getInvoiceDate() {
      /// return date from first invoice with type Normal and status Done
      if (!this.content.invoices) return "";
      // console.log(this.content.invoices)
      const normalDoneInvoice = this.content.invoices.find(
        (i) => i.invoice_type == "Normal" && i.invoice_status == "Done"
      );
      /// if getInvoiceDate is null try to find New invoice
      if (!normalDoneInvoice) {
        const normalNewInvoice = this.content.invoices.find(
          (i) => i.invoice_type == "Normal" && i.invoice_status == "New"
        );
        return normalNewInvoice ? dateUtils.formatDate(normalNewInvoice.doc_date) : "__.__.____";
      }
      return normalDoneInvoice ? dateUtils.formatDate(normalDoneInvoice.doc_date) : "__.__.____";
    },
    formFields() {
      let bookletType = '';
      bookletType = this.content.sell_form && this.content.sell_form.doc_service_booklet_handed_over_type == 'd' ? '(digitalna)' : '(knjižica)';
      return {
        rows: [
          [
            { h5: { type: "h5", label: "Dokumentacija vozila" } },
            {
              doc_trafic_licence_handed_over: {
                label: "Saobraćajna dozvola",
                ifTrue: "predata kupcu",
                type: "checkbox",
                noDamageLabel: true,
                default: "nije predata kupcu",
              },
            },
            {
              doc_service_booklet_handed_over: {
                label: "Servisna knjižica",
                ifTrue: `predata uredno popunjena ${bookletType}`,
                type: "checkbox",
                noDamageLabel: true,
                default: "nije predata kupcu",
              },
            },

            {
              doc_insurance_handed_over: {
                label: "Polisa osiguranja Auto Odgovornosti",
                ifTrue: "predata kupcu",
                type: "checkbox",
                noDamageLabel: true,
                default: "nije predata kupcu",
              },
            },
            {
              doc_spare_key_handed_over: {
                label: "Rezervni ključ ",
                ifTrue: "predat kupcu",
                type: "checkbox",
                noDamageLabel: true,
                default: "nije predat kupcu",
              },
            },
          ],
          [
            {
              h5: {
                type: "h5",
                label: "Ispravnost i funkcionalnost unutrašnjosti i komandi",
              },
            },

            {
              engine_start: {
                label: "Kontakt i start motora",
                ifTrue: "automobil startuje bez problema",
                type: "checkbox",
              },
            },
            {
              in_line_performace: {
                label: "Ubrzanje vozila",
                ifTrue: "u skladu sa performansama vozila",
                type: "checkbox",
              },
            },
            {
              gearbox_ok: {
                label: "Prenosni mehanizam - menjač",
                type: "checkbox",
              },
            },
            { brake_ok: { label: "Kontrola kočnice", type: "checkbox" } },
            {
              parking_brake_ok: {
                label: "Provera ručne kočnice",
                type: "checkbox",
              },
            },
            {
              steering_ok: { label: "Upravljački mehanizam", type: "checkbox" },
            },
            {
              lighting_ok: {
                label: "Signalizacija i osvetljenje na i u vozilu",
                type: "checkbox",
              },
            },
            { siren_ok: { label: "Sirena", type: "checkbox" } },
            { radio_ok: { label: "Radio", type: "checkbox" } },
            {
              air_conditioning_ok: { label: "Klima uređaj", type: "checkbox" },
            },
            { heating_ok: { label: "Grejanje", type: "checkbox" } },
            { cabin_fan_ok: { label: "Ventilator kabine", type: "checkbox" } },
            {
              cabin_light_ok: {
                label: "Svetlo u kabini P+Z",
                type: "checkbox",
              },
            },
            {
              interior_mirror_ok: {
                label: "Unutrašnji retrovizor",
                type: "checkbox",
              },
            },
            {
              exretnal_mirror_ok: {
                label: "Elektro/manuelno podesivi spoljni retrovizori",
                type: "checkbox",
              },
            },
            { lighter_ok: { label: "Upaljač/pepeljara", type: "checkbox" } },
            {
              seat_belts_ok: { label: "Sigurnosni pojasevi", type: "checkbox" },
            },
            {
              windows_ok: {
                label: "Elektro ili manuelni podizači stakala",
                type: "checkbox",
              },
            },
            { rear_shelf: { label: "Zadnja polica", type: "checkbox" } },
            {
              air_bags_ok: {
                label: "Vazdužni jastuci (Air Bag)",
                type: "checkbox",
              },
            },
            { wipers_ok: { label: "Kontrola rada brisača", type: "checkbox" } },
            { car_board_ok: { label: "Instrument tabla", type: "checkbox" } },
            {
              front_seats_adjustment_ok: {
                label: "Podešavanje prednjih sedišta",
                type: "checkbox",
              },
            },
            {
              rear_seats_adjustment_ok: {
                label: "Podešavanje zadnjih sedišta",
                type: "checkbox",
              },
            },
            { front_seats_ok: { label: "Sedišta prednja", type: "checkbox" } },
            { rear_seats_ok: { label: "Sedišta zadnja", type: "checkbox" } },
            {
              other_doors_ok: {
                label: "Tapaciri pr. i zad. vrata, gepeka, krova vozila",
                type: "checkbox",
              },
            },
          ],
          [
            {
              h5: {
                type: "h5",
                label: "Ispravnost i funkcionalnost spoljašnjost",
              },
            },

            {
              hood_ok: {
                label: "Poklopac motora, bočna i vrata gepeka",
                type: "checkbox",
              },
            },
            { alu_wheels_ok: { label: "Alu Felne", type: "checkbox" } },

            { antenna_ok: { label: "Antena", type: "checkbox" } },
            { wiper_blades_ok: { label: "Metlice brisača", type: "checkbox" } },
          ],
          [
            {
              h5: {
                type: "h5",
                label: "Ispravnost i funkcionalnost ispod haube",
              },
            },
            { engine_ok: { label: "Motor sa sklopovima", type: "checkbox" } },
            { accumulator_ok: { label: "Akumulator", type: "checkbox" } },
          ],
          [
            {
              pneumatic: {
                type: "h5",
                label: "Pneumatici",
              },
            },
            { fl_tire_ok: { label: "Prednji levi", type: "text" } },
            { fr_tire_ok: { label: "Prednji desni", type: "text" } },
            { rl_tire_ok: { label: "Zadnji levi", type: "text" } },
            { rr_tire_ok: { label: "Zadnji desni", type: "text" } },
            { spare_tire_ok: { label: "Rezervni pneumatik", type: "text" } },
          ],
          [
            {
              h5: {
                type: "h5",
                label: "Alat",
              },
            },
            {
              wheel_wrench_ok: {
                label: "Ključ za točkove",
                type: "checkbox",
                ifTrue: "ima",
              },
            },
            {
              crane_ok: {
                label: "Dizalica sa ključem",
                type: "checkbox",
                ifTrue: "ima",
              },
            },
            {
              tire_repair_kit_ok: {
                label: "Set za reparaciju pneumatika",
                ifTrue: "ima",
                type: "checkbox",
              },
            },
          ],
          [
            {
              h5: {
                type: "h5",
                label: "Čistoća",
              },
            },
            {
              exterior_clean: {
                label: "Spoljašnjost",
                type: "checkbox",
                ifTrue: "čista",
              },
            },
            {
              interior_clean: {
                label: "Unutrašnjost",
                type: "checkbox",
                ifTrue: "čista",
              },
            },
          ],
        ],
      };
    },
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid gray; */
  width: 800px;
  height: 900px;
}
.container .row > .col,
.container .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* background-color: rgba(39, 41, 43, 0.03); */
  /* border: 1px solid rgba(39, 41, 43, 0.1); */
}

.logo {
  padding: 0 !important;
}

img {
  height: 120px;
}

.sig {
  float: right;
}
tr {
  vertical-align: middle;
}
.gray {
  background: lightgray;
}
</style>